<template>
	<b-modal v-if="user" v-model="show" ref="userViewModal" :title="user.fullName" size="lg" @hide="$emit('user-view-modal-closed')" ok-only lazy>
	  <b-container fluid>
	   <UserView :user="user" @close="close()" />
	  </b-container>
	  <div slot="modal-footer" class="w-100">
       <b-button-group class="float-right">
	       <b-btn variant="success" @click="show=false">
	         Close
	       </b-btn>
	       <b-btn variant="warning" @click="show=false" :to="{ path: '/user/form', query: { uid: user.id }}">
	         Edit
	       </b-btn>
       </b-button-group>
     </div>
	</b-modal>
</template>

<script>
	import UserView from './UserView.vue'
	
	export default {
	  name: 'UserViewModal',
	  components: {
	    UserView
	  },
	  props: {
			uid: {
				type: Number
			}
		},
	  data () {
	    return {
	      show: false,
        user: {}
	    }
	  },
	  methods: {
			async open (uid) {
				const user = await this.$store.dispatch('getUser', uid)
				if (user) {
					this.user = user
					this.$refs.userViewModal.show()
				}
			},
	    close () {
	      this.$refs.userViewModal.hide()
	    }
	  },
	  watch: {
	    uid (val) {
	      if (val) {
	        this.open(val)
	      }
	    }
	  }
	}
</script>