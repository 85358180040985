<template>
  <div className="animated">
    <b-card>
      <p>Use this page to look up a specific participant's details. To see registraion related info, look in the event's Participants section.</p>
    </b-card>
    <i class="icon-menu mr-1"></i>Participants List<hr />
      <v-server-table 
        class="bg-light p-2 vue-table-table"
        name="dataTableParticipantList"
        :columns="columns"
        :url="url"
        :options="options"
        :useVuex="true"
        :theme="theme"
        id="dataTable"
        :preserveState="preserveState"
      >
        <template slot="child_row" slot-scope="props">
          <div><b>Gender:</b> {{props.row.participant.gender}}</div>
          <div><b>DOB:</b> {{props.row.participant.dob | moment("MMMM Do YYYY") }}</div>
        </template>
        <template slot="actions" slot-scope="props" class="text-center">
          <b-button-group>
            <b-btn size="sm" variant="info" :to="{ name: 'participantDetails', query: { pid: props.row.participant.id } }">View</b-btn>
            <b-btn size="sm" variant="warning" :to="{ name: 'participantForm', query: { pid: props.row.participant.id }}">Edit</b-btn>
          </b-button-group>
        </template>
      </v-server-table>
    <!-- view -->
    <user-view-modal :uid="viewUid" @user-view-modal-closed="viewUid = null" />
  </div>
</template>

<script>
import Vue from 'vue'
import { HTTP } from '../../services/http-common'
import { ServerTable, Event } from 'vue-tables-2-premium'
import UserViewModal from '../../components/UserViewModal'
import { mapGetters, mapActions } from 'vuex'

Vue.use(ServerTable)

export default {
  name: 'ParticipantsList',
  components: {
    Event,
    UserViewModal
  },
  props: {
    userRole: {
      type: String,
      default: null
    }
  },
  data: function () {
    return {
      columns: ['participant.fullName', 'event.title', 'actions'],
      options: {
        perPage: 30,
        perPageValues: [30,60,90],
        requestFunction (data) {
            return HTTP.get(this.url, {
                params: data
            }).catch(function (e) {
                this.dispatch('error', e)
            }.bind(this))
        },
        requestAdapter (data) {
          let query = {
            sort: data.orderBy ? data.orderBy : 'fullName',
            direction: data.ascending ? 'asc' : 'desc',
            page: data.page ? data.page : '1'
          }
          if (data.query !='') {
            query.fullName = data.query
          }
          return query
        },
        responseAdapter ({data}) {
          return {
            data: data['hydra:member'],
            count: data['hydra:totalItems']
          }
        },
        headings: {
          'participant.fullName': 'Name',
          'event.title': 'Event',
          actions: 'Actions'
        },
        sortable: ['participant.fullName', 'participant.event'],
        filterable: ['participant.fullName', 'participant.event'],
        sortIcon: { base:'fa', up:'fa-sort-asc', down:'fa-sort-desc', is:'fa-sort' },
        pagination: {
          chunk: 5,
          edge: false,
          nav: 'scroll'
        }
      },
      preserveState: true,
      useVuex: true,
      theme: 'bootstrap4',
      template: 'default',
      viewUid: null
    }
  },
  computed: {
    ...mapGetters([
      // 'participants',
      'business'
    ]),
    url () {
      return this.currentBusiness.id ? '/event_registrations?business=' + this.currentBusiness.id : '/event_registrations'
    },
    // participantdata () {
    //   let participants = []
    //   HTTP.get(this.url, {
    //       params: this.options.requestAdapter({})
    //   }).then(resp => {
    //     participants = resp.data["hydra:member"]
    //   })
    //   return participants
    // }
  },
  methods: {
    ...mapActions([
      'getParticipants'
    ]),
    doGetParticipants(){
      let params = {}
      if (this.currentBusiness.id !== null) {
        params.businesses = this.currentBusiness.id
      }
      this.getParticipants(params)
    }
  },
  filters: {
    formatRole: function (value) {
      if (!value) return ''
      value = value.substring(4).toLowerCase()
      return value.charAt(0).toUpperCase() + value.slice(1)
    }
  },
  watch: {
    userRole (val) {
      // this.doGetParticipants()
    }
  },
  created () {
    // this.doGetParticipants()
  },
  destroyed() {
    // this.$store.dispatch('resetParticipants')
  }
};
</script>
