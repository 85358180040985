<template>
  <div>
	  <b-row>
	    <b-col md="6">
	     location: {{ user.city }}, {{ user.state }} 
	    </b-col>
	    <b-col md="6">
	     Joined: {{ user.created | moment("MM/DD/YYYY") }}
	    </b-col>
	  </b-row>
	  
	  <b-row>
	    <b-col md="6">
	     Franchise(s): <span v-for="business in user.businesses" :key="business.id">
        <router-link v-if="isAdmin" :to="{ name: 'businessForm', params: { bid: business.id } }">{{ business.areaName }} (#{{ business.id }})</router-link>
        <a v-else :href="'https://' + sitePublicDomain + '/' + business.uriName" target="_blank">{{ business.areaName }}</a>, 
       </span>
	    </b-col>
	    <b-col md="6">
	      Role: {{ userRole }}
        <template v-if="userSports"><br />Sports: {{ userSports }}</template>
	    </b-col>
	  </b-row>
	  
	  <b-row>
	    <b-col md="6">
	      Username: {{ user.username }}
	    </b-col>
	    <b-col md="6">
	      Email: <a :href="'mailto:' + user.email" :title="'Email ' + user.fullName + ' <' + user.email + '>'">{{ user.email }}</a>
	    </b-col>
	  </b-row>
	  
	  <div v-if="userRole === 'MEMBER'">
	    <hr />
		  <b-row>
        <b-col>
          <b>Registered Participants:</b>
        </b-col>
		  </b-row>
      <b-row v-for="(pr, index) in participantRalationships" :key="pr.id">
        <b-col class="text-primary">
          <router-link
            :to="{ name: 'participantDetails', query: { pid: pr.participant.id } }"
            target="_blank"
          >
            <span v-if="pr.relationship.label !== 'Self'">{{ pr.participant.fullName }}</span>
            <span v-else>Self</span>
          </router-link>
        </b-col>
      </b-row>
	    <b-row>
	      <b-col class="text-primary">
	        <router-link
            :to="{ name: 'participantForm', params: { uid: user.id } }"
            class="text-success"
            target="_blank"
          >
            New Participant
          </router-link>
	      </b-col>
	    </b-row>
	  </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
export default  {
  name: 'UserView',
  props: {
    user: {
      default: {
        roles: [],
        joined: null,
        business_id: null,
        city: null,
        state: null,
        sports: []
      }
    }
  },
  data () {
    return {
    }
  },
  computed: {
    ...mapGetters([
      'participantRalationships'
    ]),
    userRole () {
      if (this.user.created) {
	      for (let i in this.userRoleOptions) {
	        if (this.userRoleOptions[i].value === this.user.roles[0]) {
	          return this.userRoleOptions[i].text
	        }
	      }
      }
    },
    userSports () {
      const userSports = this.$store.getters.sports.map((e) => {
        if (this.user.sports.includes(e['@id'])) {
          return e.label
        }
      }).filter((e) => e)
      return (userSports.length) ? userSports.join(', ') : false
    }
  },
  methods: {
    ...mapActions([
      'getParticipantRelationships',
      'resetParticipantRelationships'
    ])
  },
  mounted () {
    if (this.user.roles.includes('ROLE_MEMBER')) {
      this.getParticipantRelationships({user: this.user.id})
    }
  },
  destroyed () {
    this.resetParticipantRelationships()
  }
}
</script>